@font-face {
  font-family: 'Roobert';
  src: url('/assets/fonts/Roobert-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: 'Roobert';
  src: url('/assets/fonts/Roobert-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'Roobert';
  src: url('/assets/fonts/Roobert-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: semi-bold;
}

@font-face {
  font-family: 'Roobert';
  src: url('/assets/fonts/Roobert-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: bold;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roobert';
}
textarea, button, input {
  font-family: 'Roobert';
}
::-webkit-input-placeholder {
  font-family: 'Roobert';
}
::-moz-placeholder {
  font-family: 'Roobert';
}
::-ms-input-placeholder {
  font-family: 'Roobert';
}
::-moz-placeholder {
  font-family: 'Roobert';
}

.Toastify__toast {
  background-color: transparent !important;
  padding: 0px !important;
  box-shadow: none !important;
  overflow: visible !important;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

:root {
  --toastify-toast-width: auto !important;
  --toastify-z-index: 10000;
}
